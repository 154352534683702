import { useState, useEffect } from "react";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { getLocalStorage, setLocalStorage } from "../hooks/appVariables";

const GreetingModal = () => {
    // Retrieve the showOnStart setting from localStorage (default to true if not present)
    const showOnStart = getLocalStorage(process.env.REACT_APP_SHOW_STARTUP) !== "false";

    const [modal, setModal] = useState(showOnStart);
    const [doNotShowAgain, setDoNotShowAgain] = useState(false);

    const toggle = () => setModal(!modal);

    const handleCheckboxChange = (e) => {
        setDoNotShowAgain(e.target.checked);
    };

    const handleCloseModal = () => {
        if (doNotShowAgain) {
            setLocalStorage(process.env.REACT_APP_SHOW_STARTUP, "false");
        } else {
            setLocalStorage(process.env.REACT_APP_SHOW_STARTUP, "true");
        }
        setModal(false);
    };

    // Ensure modal stays hidden if 'do not show again' was previously checked
    useEffect(() => {
        if (!showOnStart) {
            setModal(false);
        }
    }, [showOnStart]);

    return (
        <>
            {modal && (
                <Modal isOpen={modal} toggle={toggle} size="xl" centered={true}>
                    <ModalHeader toggle={toggle}>We are delighted to announce the release of version 2.1.0 of Maintel Portal!</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col className='display-8'>
                                <p>Raise queries, enquiries, or change requests for your on-premise, cloud, or mobile estate with ease using our re-designed ticket creation wizard.</p>
                                <p>Using a simple step-by-step system, the new wizard helps capture all of the key information needed to ensure Maintel can provide the help you need quickly and efficiently. You can also add attachments, such as log files and screenshots, to help provide further detail and aid troubleshooting efforts.</p>
                                <div
                                    style={{
                                        position: 'relative',
                                        paddingBottom: '56.25%', // 16:9 aspect ratio
                                        height: 0,
                                        overflow: 'hidden',
                                        maxWidth: '100%',
                                        background: '#000',
                                    }}
                                >
                                    <iframe
                                        src="https://www.youtube-nocookie.com/embed/97WjonCK8Oo?si=BRC0nObzaWEiURhb&rel=0"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            border: 0,
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: "space-between" }}>
                        <FormGroup check style={{ display: "flex" }}>
                            <Input
                                type="checkbox"
                                id="chkDoNotShow"
                                checked={doNotShowAgain}
                                onChange={handleCheckboxChange}
                            />
                            <Label check for='chkDoNotShow' style={{ marginBottom: "0" }} className="ms-3">
                                Do not display this notification again.
                            </Label>
                        </FormGroup>
                        <Button className='no-print' color="primary" onClick={handleCloseModal}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
}

export default GreetingModal;
